import {defineStore} from 'pinia'
import {pageSizes} from 'src/components/v1/order-list/config'
import type {GetOrdersPayload} from 'src/core/orders/orders.model'
import {computed, ref, type Ref} from 'vue'
import type {Tenant} from './core/tenants/tenants.model'
import {ModalTypeEnum} from './enums'
import {LocalStorageKeys, UserType} from './interfaces/storage'
import {TenantCMS} from './enums'

export const fetchOrdersPayloadInitialValue = {
  page: 1,
  pageSize: pageSizes[0],
  ordering: '-order_date',
}

export const shopifyActions = {
  addCost: true,
  addDiscount: true,
  validateOrder: true,
  displayCMSLink: true,
  updateChildOrderDeliveryAddress: true,
  updateOrderStatus: true,
  waiveReturnFee: true,
  waiveDeliveryFee: true,
}

export const SFCCActions = {
  addCost: false,
  addDiscount: false,
  displayCMSLink: false,
  updateChildOrderDeliveryAddress: false,
  updateOrderStatus: false,
  validateOrder: false,
  waiveReturnFee: true,
  waiveDeliveryFee: false,
}

export const useStore = defineStore('store', () => {
  const tenant = ref<Tenant | undefined>()
  const tenantAllowedActions = computed(() => {
    if (tenant.value?.cms === TenantCMS.SFCC) return SFCCActions
    else return shopifyActions
  })

  const isAdmin = ref(localStorage.getItem(LocalStorageKeys.UserType) === UserType.Admin)
  const isUserLogged = ref(false)

  const latestActiveOrdersTab = ref<'orders' | 'returns' | undefined>()
  const fetchOrdersPayload: Ref<GetOrdersPayload> = ref({...fetchOrdersPayloadInitialValue})
  const openedModal: Ref<ModalTypeEnum | undefined> = ref()
  return {
    tenant,
    tenantAllowedActions,
    isAdmin,
    isUserLogged,
    fetchOrdersPayload,
    latestActiveOrdersTab,
    openedModal,
  }
})
