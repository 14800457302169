import {BrowserTracing} from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import {VueQueryPlugin} from '@tanstack/vue-query'
import {createPinia} from 'pinia'
import {createApp} from 'vue'
import App from './App.vue'
import './assets/main.css'
import router from './router'
import 'polyfill-object.fromentries'

const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true,
  enabled: import.meta.env.VITE_ENVIRONMENT === 'production',
})

import 'vue-universal-modal/dist/index.css'

import VueUniversalModal from 'vue-universal-modal'

app.use(VueUniversalModal, {
  teleportTarget: '#modals',
})

app.use(createPinia())
app.use(VueQueryPlugin)
app.use(router)

app.mount('#app')
