<script lang="ts" setup>
  import type {Tenant} from 'src/core/tenants/tenants.model'
  import {computed} from 'vue'

  const {tenants, selectedTenant} = defineProps<{
    tenants: Tenant[]
    selectedTenant?: Tenant
  }>()

  const emit = defineEmits(['changeTenant'])

  const compareMerchants = (a: Tenant, b: Tenant) => {
    return a.name.localeCompare(b.name)
  }
  const sortedTenants = computed(() => {
    const kaai = tenants.find(t => t.name === 'kaai')
    if (kaai) {
      return [kaai, ...tenants.filter(t => t.name !== 'kaai').sort(compareMerchants)]
    }
    return [...tenants].sort(compareMerchants)
  })

  const noTenantSelected = computed(() => !!selectedTenant?.id)

  const changeTenant = ($event: Event) => {
    const tenantId = ($event.target as HTMLInputElement).value
    emit('changeTenant', parseInt(tenantId))
  }
</script>
<template>
  <select
    class="rounded-md"
    @change="changeTenant"
  >
    <option :selected="noTenantSelected">Sélection...</option>
    <option
      v-for="tenant in sortedTenants"
      :key="tenant.id"
      :value="tenant.id"
      :selected="tenant.id === selectedTenant?.id"
    >
      {{ tenant.name }}
    </option>
  </select>
</template>
