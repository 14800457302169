import {axiosApiInstance} from 'src/core/api'
import {transformTenant} from './tenants.datatransformer'
import type {Tenant} from './tenants.model'

class TenantsService {
  async getTenant(tenantId: number): Promise<Tenant> {
    const response = await axiosApiInstance.get(`tenants/${tenantId}`)
    return transformTenant(response.data)
  }

  async getTenants(): Promise<Tenant[]> {
    const response = await axiosApiInstance.get(`tenants`)
    return response.data.map((tenant: Tenant) => transformTenant(tenant))
  }
}

export default new TenantsService()
