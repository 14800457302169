export enum OrderStatus {
  PENDING_AUTHORIZATION = 'pending_authorization',
  INITIAL_PAYMENT_EXPIRED = 'initial_payment_expired',
  PENDING_DELIVERY_TRACKING_INFORMATION = 'pending_delivery_tracking_information',
  IN_DELIVERY_SHIPPING = 'in_delivery_shipping',
  IN_TRIAL = 'in_trial',
  PENDING_DROPOFF = 'pending_dropoff',
  IN_RETURN_SHIPPING = 'in_return_shipping',
  PENDING_RETURN_CONTROL = 'pending_return_control',
  CANCELED_BY_ADMIN = 'canceled_by_admin',
  CLOSED = 'closed',
}

export enum RetoolPostMessageType {
  SNIPPET_READY = 'intercom-snippet__ready',
}

export enum ControlOutcomeEnum {
  ACCEPTED = 'accepted',
  REFUSED = 'refused',
  ACCEPTED_BAD_CONDITION = 'accepted_bad_condition',
  NOT_RECEIVED = 'not_received',
}

export enum CustomerRequestEnum {
  KEEP = 'keep',
  GET_REFUND = 'get_refund',
  GET_GIFTCARD = 'get_giftcard',
  EXCHANGE = 'exchange',
  INSTANT_EXCHANGE = 'instant_exchange',
  REMOVED = 'removed',
}

export enum SessionStorageKeys {
  orderId = 'orderId',
  tenantName = 'tenantName',
  accessToken = 'accessToken',
  savedStore = 'savedStore',
  devStore = 'devStore',
}

export enum ManualControlActionReasonEnum {
  UNKNOWN_CUSTOMER_REQUEST = 'UNKNOWN_CUSTOMER_REQUEST',
  INCONSISTENCY_PACKAGE = 'INCONSISTENCY_PACKAGE',
  NOT_CONFORM = 'NOT_CONFORM',
  UNEXPECTED_CONTROL = 'UNEXPECTED_CONTROL',
  NO_AUTOMATIC_CONTROL_SENT_BY_LOGISTICIAN = 'NO_AUTOMATIC_CONTROL_SENT_BY_LOGISTICIAN',
}

export enum productCondition {
  good = 'Bon',
  bad = 'Mauvais',
  refused = 'Refusé',
}

export enum returnType {
  giftCard = "Bon d'achat",
  refund = 'Remboursement',
  exchange = 'Echange',
  instant_exchange = 'Echange Instantané',
}

export enum TenantCMS {
  SFCC = 'sfcc',
  SHOPIFY = 'shopify',
}

export enum ModalTypeEnum {
  CANCEL_ORDER = 'cancel-order',
  CANCEL_EXCHANGE = 'cancel-exchange',
  CANCEL_LINE_ITEM_GIFT_CARD = 'cancel-line-item-gift-card',
  CLOSE_VANILLA_ORDER = 'close-vanilla-order',
  EDIT_EXCHANGE_SHIPPING_ADRESS = 'edit-exchange-shipping-adress',
  EDIT_DROPOFF_LIMIT_END_DATE = 'edit-dropoff-limit-end-date',
  EDIT_TRIAL_LIMIT_END_DATE = 'edit-trial-limit-end-date',
  FORCE_ORDER_STATUS = 'force-order-status',
  CONTROL_ORDER = 'control-order',
  EXCHANGE_LINE_ITEM_DETAILS = 'exchange-line-item-details',
  RETURN_REASONS = 'return-reasons',
}

export enum WaiveFeeTypes {
  DELIVERY = 'delivery',
  RETURN = 'return',
}
