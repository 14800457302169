<script lang="ts" setup>
  import TenantPicker from '../admin/TenantPicker.vue'
  import NavLink from './NavLink.vue'
  import TopLogo from './TopLogo.vue'
  import UserBar from './UserBar.vue'
  import NavDropdown from './NavDropdown.vue'
  import type {Tenant} from 'src/core/tenants/tenants.model'
  import router, {
    ordersPath,
    pathNames,
    returnReasonsPath,
    returnsPath,
    revenuesPath,
  } from 'src/router'

  defineProps<{
    tenants: Tenant[]
    selectedTenant?: Tenant
    isAdmin: boolean
  }>()

  const emit = defineEmits(['onTenantSelected'])

  const goToHelp = () => {
    window.open(
      'https://elyn-io.notion.site/Votre-kit-commer-ant-5fac16b02d7d4357813f1e47533110b5',
      '_blank',
    )
  }
</script>

<template>
  <div class="w-60 h-screen flex flex-col justify-between select-none sticky top-0">
    <TopLogo />
    <div class="flex flex-col justify-between bg-[#2e302f] h-full p-4 gap-2">
      <div class="flex flex-col gap-1">
        <div v-if="selectedTenant">
          <NavLink
            page-name="Commandes"
            icon="shopping_basket"
            :is-selected="router.currentRoute.value.name === pathNames.orders"
            @click="router.push(ordersPath(selectedTenant.id.toString()))"
          />
          <NavLink
            page-name="Retours"
            icon="swap_horiz"
            :is-selected="router.currentRoute.value.name === pathNames.returns"
            @click="router.push(returnsPath(selectedTenant.id.toString()))"
          />
          <NavDropdown
            v-if="selectedTenant.retoolAccessToken"
            :dropdown-name="'Analytics'"
            :dropdown-icon="'analytics'"
            :links="[
              {
                linkIcon: 'monetization_on',
                linkName: 'Revenus',
                linkRoute: revenuesPath(selectedTenant.id.toString()),
                isSelected: router.currentRoute.value.name === pathNames.revenues,
              },
              {
                linkIcon: 'psychology_alt',
                linkName: 'Retours',
                linkRoute: returnReasonsPath(selectedTenant.id.toString()),
                isSelected: router.currentRoute.value.name === pathNames.returnReasons,
              },
            ]"
          />
        </div>
        <TenantPicker
          v-if="isAdmin && tenants && tenants.length"
          class="mt-5"
          :tenants="tenants"
          :selected-tenant="selectedTenant"
          @change-tenant="emit('onTenantSelected', $event)"
        />
      </div>
      <NavLink
        page-name="Help Center"
        icon="help"
        @click="goToHelp"
      />
    </div>
    <UserBar />
  </div>
</template>
