<script lang="ts" setup>
  import router from 'src/router'
  import {computed, ref} from 'vue'
  import {Collapse} from 'vue-collapsed'
  import MaterialsIcon from '../icons/MaterialsIcon.vue'

  const props = defineProps<{
    dropdownName: string
    dropdownIcon: string

    links: {
      linkName: string
      linkRoute: string
      linkIcon: string
      isSelected: boolean
    }[]
  }>()

  const islinkSelected = computed(() => props.links.some(l => l.isSelected))
  const isExpand = ref(islinkSelected.value)
</script>

<template>
  <span class="flex items-center justify-between cursor-pointer poppins">
    <div class="flex flex-col w-full">
      <div
        class="flex items-center gap-3"
        @click="isExpand = !isExpand"
        :class="{
          ' py-2 pl-4 pr-2 rounded-xl ': true,
          'bg-black text-white': islinkSelected,
          'text-gray-400': !islinkSelected,
        }"
      >
        <MaterialsIcon
          :class="{
            'text-yellow-400': islinkSelected,
            'text-[20px]': true,
          }"
          :icon="dropdownIcon"
        />
        <span class="flex items-center text-[13px] font-semibold">
          {{ dropdownName }}
        </span>
        <MaterialsIcon
          v-if="!isExpand"
          class="text-[20px] ml-auto"
          icon="expand_more"
        />
        <MaterialsIcon
          v-else
          class="text-[20px] ml-auto text-yellow-400"
          icon="expand_less"
        />
      </div>
      <Collapse :when="isExpand"
        ><ul class="pt-0.5 space-y-1 pl-4">
          <li
            v-for="link in links"
            :key="link.linkName"
          >
            <div
              :class="{
                'flex items-center gap-3 text-[13px] py-2 pl-4 pr-2 rounded-xl ': true,
                'bg-black text-white': link.isSelected,
                'text-gray-400': !link.isSelected,
              }"
              @click="router.push(link.linkRoute)"
            >
              <MaterialsIcon
                class="text-[20px]"
                :icon="link.linkIcon"
              />
              <span>{{ link.linkName }}</span>
            </div>
          </li>
        </ul>
      </Collapse>
    </div>
  </span>
</template>
