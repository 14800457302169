<script lang="ts" setup>
  import {computed, toRefs} from 'vue'

  import {statusMapping as mapping} from 'src/constants'
  import type {OrderStatus} from 'src/enums'

  const props = defineProps<{
    status: OrderStatus
    isHoverable?: boolean
    isExpand?: boolean
  }>()

  const {status, isHoverable, isExpand} = toRefs(props)

  const fixedClasses =
    'rounded-xl select-none text-[9px] uppercase poppins flex hind font-bold h-[20px] items-center justify-center px-2 py-1 text-iconText'
  const classes = computed(() =>
    [
      fixedClasses,
      status.value in mapping ? mapping[status.value].colorClass : '',
      status.value in mapping && !!isHoverable.value && isHoverable.value
        ? mapping[status.value].hoverableColorClass
        : '',
      isExpand?.value ? 'w-full' : 'w-36',
    ].join(' '),
  )
</script>

<template>
  <div :class="classes">
    <span class="leading-none">{{ status in mapping ? mapping[status].french : status }}</span>
  </div>
</template>
