<script lang="ts" setup>
  import {RouterLink} from 'vue-router'
  import OrderTypeSmallBadge from '../badges/OrderTypeSmallBadge.vue'
  import MaterialsIcon from '../icons/MaterialsIcon.vue'

  const props = defineProps<{
    orderId: number
    displayedOrderId: string
    orderType: 'try_before_you_buy' | 'vanilla' | 'exchange'
    hasManualActionControl: boolean
  }>()
</script>

<template>
  <RouterLink :to="`orders/${props.orderId}`">
    <div class="flex items-center text-black">
      <OrderTypeSmallBadge
        class="flex-none"
        :type="props.orderType"
      />

      <span class="flex-grow text-[13px] font-semibold ml-2 mt-1 text-iconText">
        {{ props.displayedOrderId }}
      </span>

      <MaterialsIcon
        v-if="props.hasManualActionControl"
        class="flex-none text-red-500 ml-2"
        icon="warning_amber"
      />
    </div>
  </RouterLink>
</template>
