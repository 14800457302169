import {isLoggedIn} from 'axios-jwt'
import {storeToRefs} from 'pinia'
import tenantsService from 'src/core/tenants/tenants.service'
import {LocalStorageKeys} from 'src/interfaces/storage'
import {useStore} from 'src/store'
import {createRouter, createWebHistory} from 'vue-router'

export enum OrdersListStatusParam {
  PendingReturnControl = 'pending_return_control',
}

export const paths = {
  home: '/',
  order: '/tenants/:tenantId/orders/:orderId',
  orders: '/tenants/:tenantId/orders',
  returns: '/tenants/:tenantId/returns',
  revenues: '/tenants/:tenantId/revenues',
  returnReasons: '/tenants/:tenantId/return-reasons',
  login: '/login',
  logout: '/logout',
  anyOther: '/:pathMatch(.*)*',
}

export const pathNames = {
  home: 'Home',
  order: 'order',
  orders: 'Commandes',
  returns: 'Retours',
  revenues: 'Revenus',
  returnReasons: 'Raisons de Retours',
  login: 'Login',
  logout: 'Logout',
}

const publicPages = [paths.login, paths.logout]

export const orderPath = (tenantId: string, orderId: string) =>
  paths.order.replace(':tenantId', tenantId).replace(':orderId', orderId)

export const ordersPath = (tenantId: string, status?: OrdersListStatusParam) =>
  paths.orders.replace(':tenantId', tenantId) + (status ? '?status=' + status : '')

export const returnsPath = (tenantId: string, status?: OrdersListStatusParam) =>
  paths.returns.replace(':tenantId', tenantId) + (status ? '?status=' + status : '')

export const revenuesPath = (tenantId: string, status?: OrdersListStatusParam) =>
  paths.revenues.replace(':tenantId', tenantId) + (status ? '?status=' + status : '')

export const returnReasonsPath = (tenantId: string, status?: OrdersListStatusParam) =>
  paths.returnReasons.replace(':tenantId', tenantId) + (status ? '?status=' + status : '')

export const backendHosts = {
  djangoAdmin: 'admin',
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: paths.order,
      name: pathNames.order,
      component: () => import('../views/OrderView.vue'),
    },
    {
      path: paths.orders,
      name: pathNames.orders,
      props: () => ({
        key: pathNames.orders,
      }),
      component: () => import('../views/OrdersListView.vue'),
    },
    {
      path: paths.returns,
      name: pathNames.returns,
      props: () => ({
        key: pathNames.returns,
      }),
      component: () => import('../views/OrdersListView.vue'),
    },
    {
      path: paths.revenues,
      name: pathNames.revenues,
      component: () => import('../views/StatisticsView.vue'),
      props: () => ({
        retoolUrl: import.meta.env.VITE_RETOOL_REVENUES_APP_URL,
      }),
    },
    {
      path: paths.returnReasons,
      name: pathNames.returnReasons,
      component: () => import('../views/StatisticsView.vue'),
      props: () => ({
        retoolUrl: import.meta.env.VITE_RETOOL_RETURN_REASONS_APP_URL,
      }),
    },
    {
      path: paths.logout,
      name: pathNames.logout,
      component: () => import('../views/LogOutView.vue'),
    },
    {
      path: paths.login,
      name: pathNames.login,
      component: () => import('../views/LogInView.vue'),
    },
    {
      path: paths.home,
      redirect: paths.login,
    },
    {
      path: '/orders-list-all',
      redirect: paths.login,
    },
    {
      path: paths.anyOther,
      name: 'NotFound',
      component: import('../views/NotFound.vue'),
    },
    {
      path: '/orders',
      redirect: paths.orders,
    },
    {
      path: '/returns',
      redirect: paths.returns,
    },
    {
      path: '/order/:id',
      redirect: paths.orders,
    },
  ],
})

router.beforeEach(async to => {
  const {tenant, isAdmin, isUserLogged} = storeToRefs(useStore())
  isUserLogged.value = await isLoggedIn()
  if (to.matched.some(route => route.name === 'NotFound')) return
  if (!publicPages.includes(to.path) && !isUserLogged.value) {
    // redirect to login page if not logged in and trying to access a restricted page
    return paths.login + '?next=' + to.fullPath
  }
  if (!isAdmin.value && !publicPages.includes(to.path)) {
    const tenantItem = localStorage.getItem(LocalStorageKeys.Tenant)
    if (!tenantItem) {
      const localStorageTenant = JSON.parse(
        localStorage.getItem(LocalStorageKeys.Tenant) as string,
      ).id
      if (+to.params.tenantId !== localStorageTenant) {
        to.params.tenantId = localStorageTenant
        return to
      }
    }
  }
  const tenantId = +to.params.tenantId
  if (tenantId && (!tenant.value || (tenant.value.id !== tenantId))) {
    tenant.value = await tenantsService.getTenant(tenantId)
  }
})

export default router
