<script lang="ts" setup>
  import {RouterView, useRoute} from 'vue-router'
  import NavPanel from './components/v1/navigation/NavPanel.vue'

  import {storeToRefs} from 'pinia'
  import DevBanner from './components/v1/misc/DevBanner.vue'
  import {DevEnvManager} from './dev-env-manager'
  import {useStore} from './store'
  import {ref, watch} from 'vue'
  import router, {ordersPath, pathNames} from './router'
  import tenantsService from './core/tenants/tenants.service'
  import type {Tenant} from './core/tenants/tenants.model'

  const isProdEnv = import.meta.env.VITE_ENVIRONMENT === 'production'

  const {tenant, isAdmin, isUserLogged} = storeToRefs(useStore())
  const currentRoute = useRoute()
  const tenants = ref<Tenant[]>([])

  if (!isProdEnv) {
    DevEnvManager.init()
  }

  const changeBaseUrl = (newUrl: string) => {
    if (isProdEnv) return
    DevEnvManager.changeBaseUrl(newUrl)
  }

  const fetchTenant = async () => {
    if (!tenant.value) return
    tenant.value = await tenantsService.getTenant(tenant.value.id)
  }

  const fetchTenants = async () => {
    tenants.value = await tenantsService.getTenants()
  }

  const onTenantSelected = (tenantId: number) => {
    if (tenantId === tenant.value?.id) return
    tenant.value = tenants.value.find(t => t.id === tenantId)
    if (currentRoute.name === pathNames.order) {
      const newRoute = ordersPath(tenantId.toString())
      router.push(newRoute)
    } else {
      const newRoute = {
        ...router.currentRoute.value,
        params: {tenantId: tenantId},
      }
      router.push(newRoute)
    }
  }

  watch(
    isUserLogged,
    value => {
      if (!value) return
      if (isAdmin.value) {
        fetchTenants()
      } else {
        fetchTenant()
      }
    },
    {deep: true},
  )

  watch(
    tenant,
    newTenant => {
      if (
        newTenant &&
        !newTenant.retoolAccessToken &&
        (currentRoute.name === pathNames.returnReasons || currentRoute.name === pathNames.revenues)
      ) {
        router.push(ordersPath(newTenant.id.toString()))
      }
    },
    {deep: true},
  )
</script>

<template>
  <DevBanner
    v-if="!isProdEnv"
    @change-base-url="changeBaseUrl($event)"
  />
  <div class="flex justify-start gap-4 w-screen h-full">
    <div v-if="isUserLogged">
      <NavPanel
        :is-admin="isAdmin"
        :tenants="tenants"
        :selected-tenant="tenant"
        @on-tenant-selected="onTenantSelected"
      />
    </div>
    <div class="bg-white w-full">
      <div
        :class="{
          'w-screen': !isUserLogged,
        }"
        class="h-full"
      >
        <RouterView />
      </div>
    </div>
  </div>
</template>
