import dayjs from 'dayjs'
import {OrderStatus} from './enums'

export const formatDate = (date: string | null): string | null =>
  date === null ? null : dayjs(date).format('D MMM YYYY')

export const formatDateDatePicker = (date: string | null): string | null =>
  date === null ? null : dayjs(date).format('YYYY-MM-DD')

export const cents_to_currency = (cents: number, currencyCode: string) => {
  return new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
  }).format(cents / 100)
}

export function string_to_cents(currency: string) {
  return Math.floor(parseFloat(currency) * 100)
}

export const checkEndDate = (
  endDate: string,
  vanillaIsReturnInterfaceEnabled: boolean,
  isAdmin: boolean,
) => {
  const daysDifference = dayjs(endDate).diff(dayjs(), 'days')
  if (daysDifference < 0) {
    // alert("Impossible de choisir une date dans le passé");
    throw new Error('Impossible de choisir une date dans le passé')
  }
  if (isAdmin) {
    return
  }
  const [maxTrialDaysUpdate, warningTooltip] = vanillaIsReturnInterfaceEnabled
    ? [730, 'Impossible de choisir une date au-delà de deux ans']
    : [31, "Impossible de choisir une date au-delà d'un mois"]
  if (daysDifference > maxTrialDaysUpdate) throw new Error(warningTooltip)
}

export function cents(eur: string | null) {
  if (eur === null) {
    return 0
  }
  // without Math.round, cents('2.01') = 200.99999999999997
  return Math.round(+eur * 100)
}

const camelizeStr = (str: string) => str.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase())

export const camelize = (thing: any): any => {
  if (thing == null) {
    return null
  }
  if (Array.isArray(thing)) {
    return thing.map(camelize)
  } else if (typeof thing === 'object') {
    return Object.fromEntries(
      Object.entries(thing).map(([key, value]) => [camelizeStr(key), camelize(value)]),
    )
  }
  return thing
}

export const snakifyStr = (str: string) =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const snakify = (thing: any): any => {
  if (thing === null) {
    return null
  }
  if (Array.isArray(thing)) {
    return thing.map(snakify)
  } else if (typeof thing === 'object') {
    return Object.fromEntries(
      Object.entries(thing).map(([key, value]) => [snakifyStr(key), snakify(value)]),
    )
  }
  return thing
}

const allowedActions = {
  statuses: [
    OrderStatus.PENDING_DELIVERY_TRACKING_INFORMATION,
    OrderStatus.IN_DELIVERY_SHIPPING,
    OrderStatus.IN_TRIAL,
    OrderStatus.PENDING_DROPOFF,
    OrderStatus.IN_RETURN_SHIPPING,
    OrderStatus.PENDING_RETURN_CONTROL,
    OrderStatus.CLOSED,
    OrderStatus.CANCELED_BY_ADMIN,
  ],
  displayReturnFees: [
    OrderStatus.PENDING_DROPOFF,
    OrderStatus.IN_RETURN_SHIPPING,
    OrderStatus.PENDING_RETURN_CONTROL,
    OrderStatus.CLOSED,
    OrderStatus.CANCELED_BY_ADMIN,
  ],
  displayAmountOther: [
    OrderStatus.PENDING_DROPOFF,
    OrderStatus.IN_RETURN_SHIPPING,
    OrderStatus.PENDING_RETURN_CONTROL,
    OrderStatus.CLOSED,
    OrderStatus.CANCELED_BY_ADMIN,
  ],
  declareReturn: [OrderStatus.CLOSED],
  closeVanillaOrder: [
    OrderStatus.PENDING_DELIVERY_TRACKING_INFORMATION,
    OrderStatus.IN_DELIVERY_SHIPPING,
    OrderStatus.IN_TRIAL,
  ],
  cancelOrder: [
    OrderStatus.PENDING_DELIVERY_TRACKING_INFORMATION,
    OrderStatus.IN_DELIVERY_SHIPPING,
  ],
  displayReturnType: [
    OrderStatus.PENDING_DROPOFF,
    OrderStatus.IN_RETURN_SHIPPING,
    OrderStatus.PENDING_RETURN_CONTROL,
    OrderStatus.CLOSED,
    OrderStatus.CANCELED_BY_ADMIN,
  ],
  canCancelExchange: [
    OrderStatus.PENDING_RETURN_CONTROL,
    OrderStatus.PENDING_DROPOFF,
    OrderStatus.IN_RETURN_SHIPPING,
  ],
}

export const canDeclareReturn = (status: OrderStatus, isEditingOrder: boolean): boolean => {
  return !isEditingOrder && allowedActions.declareReturn.includes(status)
}

export const canCloseVanillaOrder = (status: OrderStatus, isEditingOrder: boolean): boolean => {
  return !isEditingOrder && allowedActions.closeVanillaOrder.includes(status)
}

export const canCancelOrder = (
  status: OrderStatus,
  isEditingOrder: boolean,
  isExchange: boolean,
): boolean => {
  return !isEditingOrder && !isExchange && allowedActions.cancelOrder.includes(status)
}

export const canCancelExchange = (status: OrderStatus, isExchangeCancellable: boolean): boolean => {
  return isExchangeCancellable && allowedActions.canCancelExchange.includes(status)
}

export const canDisplayReturnType = (status: OrderStatus, isEditingOrder: boolean): boolean => {
  return isEditingOrder || allowedActions.displayReturnType.includes(status)
}

export const canDisplayReturnFees = (status: OrderStatus): boolean =>
  allowedActions.displayReturnFees.includes(status)

export const canEditFullOrder = (status: OrderStatus, isEditingOrder: boolean): boolean =>
  [OrderStatus.PENDING_RETURN_CONTROL].includes(status) || isEditingOrder

export const canEditOrderFees = (status: OrderStatus, isEditingOrder: boolean): boolean =>
  (status !== OrderStatus.CLOSED && status !== OrderStatus.IN_RETURN_SHIPPING) || isEditingOrder

export const canEditAmountOther = (status: OrderStatus, isEditingOrder: boolean): boolean =>
  status === OrderStatus.PENDING_RETURN_CONTROL || isEditingOrder

export const canEditExtraDiscount = (status: OrderStatus, isEditingOrder: boolean): boolean =>
  (status !== OrderStatus.CLOSED && status !== OrderStatus.IN_RETURN_SHIPPING) || isEditingOrder

export const canDisplayAmountOther = (status: OrderStatus): boolean =>
  allowedActions.displayAmountOther.includes(status)
